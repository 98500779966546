import React from 'react';
import './style.scss';

const GetStarted = () => {
    return (
        <div className='ptpGetStartedWrap'>
            <div className='triviaContainer'>
                <div className='getStartedHeader'>
                    <h2>
                        Three steps to get started with
                        <br />
                        peer-to-peer recognition
                    </h2>
                </div>

                <div className='ptpGetStartedSteps'>
                    <div className='ptpGetStartedContentImg'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepOne.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepOne.svg'}
                            alt=''
                        />
                    </div>
                    <div className='ptpGetStartedContent'>
                        <h4>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/add-engage-with.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/add-engage-with.svg'}
                                alt=''
                            />
                            Step 1
                        </h4>
                        <h3>
                            Add EngageWith
                            <br />
                            to Slack or Microsoft Teams
                        </h3>
                        <div className='ptpGetStartedContentImg'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepOne.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepOne.svg'}
                                alt=''
                            />
                        </div>
                        <p>
                            EngageWith is purpose-built for Slack and Microsoft Teams. Install the app from the
                            respective app directory or click ‘Add EngageWith’ from the top of this page.{' '}
                        </p>
                    </div>
                </div>

                <div className='ptpGetStartedSteps'>
                    <div className='ptpGetStartedContentImg'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepTwo.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepTwo.svg'}
                            alt=''
                        />
                    </div>
                    <div className='ptpGetStartedContent'>
                        <h4>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/spread.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/spread.svg'}
                                alt=''
                            />
                            Step 2
                        </h4>
                        <h3>Give Kudos and Shoutouts</h3>
                        <div className='ptpGetStartedContentImg'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepTwo.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepTwo.svg'}
                                alt=''
                            />
                        </div>
                        <p>
                            Go beyond fist bumps and high fives. Acknowledge and appreciate your co-workers in the
                            channel of your choice with Kudos or Shoutouts.
                        </p>
                    </div>
                </div>

                <div className='ptpGetStartedSteps'>
                    <div className='ptpGetStartedContentImg'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepThree.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepThree.svg'}
                            alt=''
                        />
                    </div>
                    <div className='ptpGetStartedContent'>
                        <h4>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/speak.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/speak.svg'}
                                alt=''
                            />
                            Step 3
                        </h4>
                        <h3>Spread happy vibes</h3>
                        <div className='ptpGetStartedContentImg'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepThree.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/stepThree.svg'}
                                alt=''
                            />
                        </div>
                        <p>
                            Office? Home Office? Or a bit of both? Appreciate your people, keep them motivated, and
                            drive positivity in everyday work.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetStarted;
