import React from 'react';
import './style.scss';
import CTAButtons from '../../CTAButtons';

const StartBuilding = () => {
    return (
        <div className='startBuildingWrap'>
            <div className='triviaContainer'>
                <div className='startBuilding'>
                    <h2>
                        Start building a culture
                        <br />
                        of appreciation
                    </h2>
                    <CTAButtons />
                </div>
            </div>
        </div>
    );
};

export default StartBuilding;
