import React from 'react';
import './style.scss';

const KickStart = () => {
    return (
        <div className='kickStartWrap'>
            <div className='triviaContainer'>
                <div className='kickStart'>
                    <div className='kickStartLeft'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/illustration.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/illustration.svg'}
                            alt=''
                        />
                    </div>
                    <div className='kickStartRight'>
                        <h3>
                            Kick-start a cycle of
                            <br />
                            recognition{' '}
                        </h3>
                        <p>
                            When you appreciate your peers publicly, they’re far more likely to extend this to someone
                            else in your team. Thus, a cycle of recognition is born.{' '}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KickStart;
