import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import Navbar from '../../Navbar';
import CTAButtons from '../../CTAButtons';

const Header = () => {
    const names = ['John', 'Mary', 'Pat'];
    const [index, setIndex] = useState(0);

    const incrementIndex = useCallback(() => {
        if (index + 1 === names.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }, [index, names.length]);

    useEffect(() => {
        setTimeout(() => incrementIndex(), 2000);
    }, [incrementIndex]);

    return (
        <div className='peerToPeerWrap'>
            <div className='triviaContainer'>
                <Navbar />

                <div className='peerToPeerContent'>
                    <div className='peerToPeerContentLeft'>
                        <h1>
                            Hey @{names[index]}
                            <br />
                            You did an <br />
                            awesome job
                        </h1>
                        <p>
                            With peer-to-peer recognition, never let great work slip past whether it’s cross-team,
                            across departments, or sometimes your entire organization.{' '}
                        </p>
                        <CTAButtons />
                    </div>
                    <div className='peerToPeerContentRight'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/header-illustration.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/peer-to-peer/header-illustration.svg'}
                            alt=''
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
